// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-art-2011-js": () => import("./../../../src/pages/art-2011.js" /* webpackChunkName: "component---src-pages-art-2011-js" */),
  "component---src-pages-art-2016-js": () => import("./../../../src/pages/art-2016.js" /* webpackChunkName: "component---src-pages-art-2016-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-art-large-collage-js": () => import("./../../../src/pages/art-large-collage.js" /* webpackChunkName: "component---src-pages-art-large-collage-js" */),
  "component---src-pages-art-magazine-collage-js": () => import("./../../../src/pages/art-magazine-collage.js" /* webpackChunkName: "component---src-pages-art-magazine-collage-js" */),
  "component---src-pages-art-outdoor-js": () => import("./../../../src/pages/art-outdoor.js" /* webpackChunkName: "component---src-pages-art-outdoor-js" */),
  "component---src-pages-art-temenos-js": () => import("./../../../src/pages/art-temenos.js" /* webpackChunkName: "component---src-pages-art-temenos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memories-js": () => import("./../../../src/pages/memories.js" /* webpackChunkName: "component---src-pages-memories-js" */),
  "component---src-pages-movement-js": () => import("./../../../src/pages/movement.js" /* webpackChunkName: "component---src-pages-movement-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-words-js": () => import("./../../../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

